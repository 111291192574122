




import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import Handsontable from "@/components/elements/Handsontable/Handsontable.vue"
import { HandsontableStyle } from "@/model/interfaces/handsontable/handsontableStyle"
import { THEMES } from "@/plugins/vuetify"
import SurveySubmissionParserService from "@/services/submission/surveySubmission/SurveySubmissionParserService"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TemplateModule from "@/store/modules/form/TemplateModule"
import { SurveySubmission } from "@/model/interfaces/submission/surveySubmission/surveySubmission"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"

@Component({
  components: { Handsontable }
})
export default class OriginalData extends Vue {
  @Ref("handsontableComponent") handsontableComponent!: Handsontable
  @Prop() search!: string

  // ///////////////////////////////////////////////////////////////////////////
  // Styles
  // ///////////////////////////////////////////////////////////////////////////
  readonly HARMONIZED_STYLE: HandsontableStyle = {
    id: "harmonized",
    mainColor: THEMES.light.primary.lighten2,
    isCellColored: true
  }

  readonly CLEANED_DATA_STYLE: HandsontableStyle = {
    id: "cleaned-data",
    mainColor: THEMES.light.primary.base,
    isCellColored: true
  }

  readonly styles: HandsontableStyle[] = [this.HARMONIZED_STYLE, this.CLEANED_DATA_STYLE]

  readonly fieldsToStyle: Record<string, string> = {
    // Harmonized
    [SurveySubmissionParserService.LAND_ACCESS]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.EMPLOYMENT_ACCESS]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.FINANCE_ACCESS]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.O_1_FPACOST_USD]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.O_1_FPACASH_COST_USD]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.O_1_MPPA_1_COST_USD]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.O_2_FPACOST_USD]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.O_2_FPACASH_COST_USD]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.O_3_FPACOST_USD]: this.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.O_3_FPACASH_COST_USD]: this.HARMONIZED_STYLE.id,

    // Data cleaned
    [SurveySubmissionParserService.HARMONIZED_HEADERS_DATA_CLEANED]: this.CLEANED_DATA_STYLE.id
  }

  async mounted(): Promise<void> {
    await this.onSurveySubmissionsChange()
  }

  get headers(): string[] {
    if (this.surveySubmissions.length > 0 && TemplateSubmissionModule.currentSubmission && TemplateModule.template) {
      return SurveySubmissionParserService.getPartnerHeaders(TemplateModule.template)
    }

    return []
  }

  get surveySubmissions(): SurveySubmission[] {
    return SurveySubmissionModule.partnerSubmissions
  }

  hasSurveySubmissions(): boolean {
    return this.surveySubmissions.length > 0
  }

  @Watch("surveySubmissions")
  async onSurveySubmissionsChange(): Promise<void> {
    await this.handsontableComponent.onChange()
  }

  get values(): Record<string, unknown>[] {
    return SurveySubmissionParserService.getFieldsValues(this.surveySubmissions)
  }
}
