






import { Component, Prop, Vue } from "vue-property-decorator"
import Chart from "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { ChartOptions } from "chart.js"
import { TreemapController, TreemapElement } from "chartjs-chart-treemap"
Chart.register(ChartDataLabels)
Chart.register(TreemapController, TreemapElement)

@Component
export default class GlobalIndicatorsChart extends Vue {
  @Prop() canvasId!: string
  @Prop() configuration!: ChartOptions
  @Prop({ default: false })
  isTreeMap!: boolean
  mounted(): void {
    const ctx = document.getElementById(this.canvasId) as HTMLCanvasElement
    const myChart = new Chart(ctx, this.configuration)
  }
}
