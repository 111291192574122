import { Vue } from "vue-property-decorator"
import { GlobalIndicatorsDataset } from "@/model/interfaces/globalIndicators/globalIndicatorsDataset"
import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import { GlobalIndicatorsAgePyramidFormatter } from "./globalIndicatorsAgePyramidFormatter"

export default class GlobalIndicatorsService {
  private static GLOBAL_INDICATORS_URI = "global_indicators"

  static async get(year: number|null, country: string|null): Promise<GlobalIndicatorsDataset> {
    if (year !== null && country !== null) {
      this.GLOBAL_INDICATORS_URI = "global_indicators?year=" + year + "&countries=" + country
    }
    if (year !== null && country === null) {
      this.GLOBAL_INDICATORS_URI = "global_indicators?year=" + year
    }
    if (year === null && country !== null) {
      this.GLOBAL_INDICATORS_URI = "global_indicators?countries=" + country
    }
    if (year === null && country === null) {
      this.GLOBAL_INDICATORS_URI = "global_indicators"
    }
    return Vue.$axios.get(this.GLOBAL_INDICATORS_URI).then(response => {
      const BENEFICIARIES_AGE_PYRAMID_BY_AOW = 'beneficiariesAgePyramidByAOW'
      response.data[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_AGRICULTURE] = GlobalIndicatorsAgePyramidFormatter.format(response.data[BENEFICIARIES_AGE_PYRAMID_BY_AOW]).O1
      response.data[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_SELF] = GlobalIndicatorsAgePyramidFormatter.format(response.data[BENEFICIARIES_AGE_PYRAMID_BY_AOW]).O2
      response.data[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_WAGE] = GlobalIndicatorsAgePyramidFormatter.format(response.data[BENEFICIARIES_AGE_PYRAMID_BY_AOW]).O3
      console.log(response.data)
      return response.data as GlobalIndicatorsDataset
    })
  }
}
