
















import { Component, Vue } from "vue-property-decorator"
import GlobalIndicatorsToolbar from "@/components/dashboard/GlobalIndicators/GlobalIndicatorsToolbar.vue"
import GlobalIndicatorsKeyNumbers from "./reusableComponents/GlobalIndicatorsKeyNumbers.vue"
import GlobalIndicatorsBeneficariesProfile from "./mainParts/GlobalIndicatorsBenificariesProfile.vue"
import GlobalIndicatorsEmploymentProfile from "./mainParts/GlobalIndicatorsEmploymentProfile.vue"
import GlobalIndicatorsFinancialProfile from "./mainParts/GlobalIndicatorsFinancialProfile.vue"
import { GlobalIndicatorsDataset } from "@/model/interfaces/globalIndicators/globalIndicatorsDataset"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import Loading from "@/components/elements/Loading.vue"
import GlobalIndicatorsCategorySelector from "./GlobalIndicatorsCategorySelector.vue"

@Component({
  components: {
    GlobalIndicatorsToolbar,
    GlobalIndicatorsKeyNumbers,
    GlobalIndicatorsCategorySelector,
    GlobalIndicatorsBeneficariesProfile,
    GlobalIndicatorsEmploymentProfile,
    GlobalIndicatorsFinancialProfile,
    Loading
  }
})
export default class GlobalIndicatorsContent extends Vue {
  get dataset(): GlobalIndicatorsDataset | null {
    return GlobalIndicatorsModule.dataset
  }
  get isLoading(): boolean {
    return GlobalIndicatorsModule.loadingData
  }
}
