import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import { AgePyramidAgeGroup, AgePyramidCategoryData, GlobalIndicatorsDataset } from "@/model/interfaces/globalIndicators/globalIndicatorsDataset"
import { LVColors } from "@/plugins/vuetify"
import { ChartConfiguration, ChartData } from "chart.js"

export class PieChartFormatter {
  static format(key: GlobalIndicatorsDatasetKey, dataset: GlobalIndicatorsDataset, labelsField: string, percentageFields: string, type: "doughnut" | "pie"): ChartConfiguration {
    const dataDescription: ChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: []
        }
      ]
    }
    for (const data of (dataset as Record<GlobalIndicatorsDatasetKey, any>)[key]) {
      try {
        dataDescription.labels?.push(data[labelsField])
        dataDescription.datasets[0].data.push(data[percentageFields])
      } catch (error) {
        console.log(error)
      }
    }
    const config: ChartConfiguration = {
      type: type,
      data: dataDescription,
      options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
          padding: {
            top: 50,
            bottom: 50,
            left: 50,
            right: 50
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            color: "black",
            anchor: "end",
            align: "end",
            font: {
              weight: "bold"
            },
            padding: 6,
            formatter: value => {
              return value ? value.toFixed(2) + "%" : ""
            }
          }
        }
      }
    }
    return config
  }
}

export class BarChartFormatter {
  static format(
    key: GlobalIndicatorsDatasetKey,
    dataset: GlobalIndicatorsDataset,
    labelName: string | string[],
    countName: string,
    displayLegend = true,
    multiple = false
  ): ChartConfiguration {
    const dataDescription: ChartData = {
      labels: [],
      datasets: []
    }
    try {
      if (!multiple) {
        dataDescription.datasets.push({
          data: [],
          backgroundColor: []
        })
        for (const data of (dataset as Record<GlobalIndicatorsDatasetKey, any>)[key]) {
          dataDescription.labels?.push(data[labelName as string])
          dataDescription.datasets[0].data.push(data[countName])
        }
      } else {
        dataDescription.labels = labelName as string[]
        for (const category in (dataset as any)[key]) {
          dataDescription.datasets.push({
            label: category,
            data: (dataset as any)[key][category],
            backgroundColor: [],
            stack: "Stack 0"
          })
        }
      }
    } catch (error) {
      console.log(error)
    }

    const config: ChartConfiguration = {
      type: "bar",
      data: dataDescription,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false
            },
            stacked: multiple
          },
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: displayLegend
          },
          datalabels: {
            display: multiple,
            color: "#FFF",
            font: {
              weight: "bold"
            },
            formatter: value => {
              return value ? value.toFixed(2) + "%" : ""
            }
          }
        }
      }
    }
    if (multiple) {
      ;(config as any).options.scales.y.max = 100
    }
    return config
  }
}

export class PyramidAgeChartFormatter {
  static format(data: AgePyramidCategoryData, displayYScale = true): ChartConfiguration {
    const labels = Object.keys(data).reverse()
    const femaleData = labels.map(ageGroup => data[ageGroup as AgePyramidAgeGroup].Female)
    const maleData = labels.map(ageGroup => data[ageGroup as AgePyramidAgeGroup].Male)
    const maxValue = Math.max(Math.abs(Math.min(...femaleData)), Math.max(...maleData)) + 1
    const config: ChartConfiguration = {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Female",
            data: femaleData,
            backgroundColor: LVColors.PRIMARY_RED,
            barThickness: 5
          },
          {
            label: "Male",
            data: maleData,
            backgroundColor: LVColors.PRIMARY_BLUE,
            barThickness: 5
          }
        ]
      },
      options: {
        indexAxis: "y",
        scales: {
          x: {
            stacked: true,
            min: -maxValue,
            max: maxValue,
            ticks: {
              callback: function(value) {
                return Math.abs(parseInt(value as string))
              }
            }
          },
          y: {
            display: displayYScale,
            title: {
              display: true,
              text: "Age",
              font: {
                size: 14
              }
            },
            grid: {
              display: false
            },
            stacked: true
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || ""
                if (label) {
                  label += ": "
                }
                if ((context.parsed as any).x !== null) {
                  label += Math.abs((context.parsed as any).x)
                }
                return label
              }
            }
          },
          datalabels: {
            display: false
          },
          legend: {
            display: false
          }
        }
      }
    }
    return config
  }
}

export class TreeMapChartFormatter {
  static format(data: any) {
    data.sort((a: any, b: any) => b.count - a.count)
    const topCategories = data.slice(0, 9)
    const othersCount = data.slice(9).reduce((sum: number, item: any) => sum + item.count, 0)
    const totalCount = topCategories.reduce((sum: number, item: any) => sum + item.count, 0)
    if (othersCount > 0) {
      topCategories.push({ origin_country: "Others", count: othersCount })
    }
    const config: ChartConfiguration = {
      type: "treemap" as const,
      data: {
        datasets: [
          {
            tree: topCategories,
            key: "count",
            groups: ["origin_country"],
            spacing: 0,
            borderColor: LVColors.WHITE,
            borderWidth: 2,
            backgroundColor: LVColors.LIGHT_BLUE_3,
            labels: {
              display: true,
              formatter: (ctx: any) => {
                const percentage = ((ctx.raw.v / totalCount) * 100).toFixed(1)
                return `${ctx.raw.g}: ${percentage}%`
              },
              font: {
                size: 9,
                weight: "bold"
              }
            }
          }
        ] as any
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: tooltipItem => {
                const label = (tooltipItem.raw as any).g
                const value = (tooltipItem.raw as any).v
                return `${label}: ${value} persons`
              }
            }
          }
        }
      }
    }

    return config
  }
}

export class ProportionnalSVGArcCreator {
  static create(quantity: number, color: string) {
    // Creation of SVG Element
    const svgNS = "http://www.w3.org/2000/svg"
    const svg: SVGSVGElement = document.createElementNS(svgNS, "svg")
    svg.setAttribute("width", "180")
    svg.setAttribute("height", "180")
    svg.setAttribute("viewBox", "0 0 36 36")

    // Set transparancy to 25%
    const baseColor = color + "40"

    // Base circle
    const circle = document.createElementNS(svgNS, "circle")
    circle.setAttribute("cx", "18")
    circle.setAttribute("cy", "18")
    circle.setAttribute("r", "15.9155")
    circle.setAttribute("fill", "transparent")
    circle.setAttribute("stroke", baseColor)
    circle.setAttribute("stroke-width", "3")
    svg.appendChild(circle)

    // Set arc when value <= 1
    const path1 = document.createElementNS(svgNS, "path")
    path1.setAttribute("fill", "transparent")
    path1.setAttribute("stroke", color)
    path1.setAttribute("stroke-width", "3")
    const part1 = Math.min(quantity, 1) * 100
    path1.setAttribute("stroke-dasharray", `${part1}, 100`)
    path1.setAttribute(
      "d",
      `
          M18 2.0845 
          a 15.9155 15.9155 0 0 1 0 31.831 
          a 15.9155 15.9155 0 0 1 0 -31.831`
    )
    svg.appendChild(path1)

    // Set arc when value > 1
    if (quantity > 1) {
      const path2 = document.createElementNS(svgNS, "path")
      path2.setAttribute("fill", "transparent")
      path2.setAttribute("stroke", color)
      path2.setAttribute("stroke-width", "3")
      const part2 = (quantity - 1) * 100
      path2.setAttribute("stroke-dasharray", `${part2}, 100`)
      path2.setAttribute(
        "d",
        `
            M18 2.0845 
            a 15.9155 15.9155 0 0 1 0 31.831 
            a 15.9155 15.9155 0 0 1 0 -31.831`
      )
      svg.appendChild(path2)
    }

    // Add text
    let roundedQuantity = null
    try {
      roundedQuantity = quantity.toFixed(2)
    } catch (e) {
      console.log(e)
    }
    const text: SVGTextElement = document.createElementNS(svgNS, "text")
    text.setAttribute("x", "18")
    text.setAttribute("y", "20.35")
    text.setAttribute("text-anchor", "middle")
    text.setAttribute("fill", "#000")
    text.setAttribute("font-size", "8")
    text.setAttribute("font-family", "Arial, sans-serif")
    text.textContent = roundedQuantity
    svg.appendChild(text)

    return svg
  }
}
